import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    super.connect()
  }

  fetch_word_info(event){
    let word = event.target.dataset.word
    $('#resultDetailsModalLetter').text(word);
    let loader_html = "<div class='m-5 text-center'><img src='/raw_assets/loader.gif' width='100%' loading='lazy'></div>"
    $('#resultDetailsModalBody').html(loader_html);
    if(!$("#resultDetailsModal").hasClass('show')) { $("#resultDetailsModal").modal("show"); }
    $.post('/results_modal?letter=' + word);
  }
  
}