import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    super.connect()
  }

  nextPage(event){
    debugger
    $(event.target.parentElement).find('svg, span').toggleClass('hidden')
    
    $.ajax({
      url: `https://fly.wordfinderapi.com/api/search`,
      type: 'GET',
      context: this,
      data: this.filter_params,
      success: function(result) {
        let buttons_html = ""
        result.word_pages[0].word_list.forEach(element => {
          buttons_html += `\n<button type='button' class='btn btn-primary tag' data-controller='tags' data-word='${element.word}' \
                                     data-action='click->tags#fetch_word_info'>\
                                     ${element.word} \
                                     <span>${element.points}</span>\
                              </button>`
        });

        $(`#words_card_${this.word_length}`).append(buttons_html)
        this.element.dataset.page = this.next_page
        $(event.target.parentElement).find('svg, span').toggleClass('hidden')
      },
      error: function(error){
        console.log('Error: ', error)
      }
    })
  }

  get filter_params(){
    return {
      length: this.filter_section.find("#length_field").val(),
      word_sorting: 'points',
      page_token: this.next_page,
      group_by_length: true,
      page_size: 30,
      dictionary: this.filter_section.find("#dictionary_field").val(),
      starts_with: this.filter_section.find("#starts_with_field").val(),
      ends_with: this.filter_section.find("#ends_with_field").val(),
      contains: this.filter_section.find('#contains_field').val()
    }
  }

  get filter_section(){
    return $("#word-count-search-bar")
  }

  get word_length(){
    return this.filter_section.find("#length_field").val()
  }

  get next_page(){
    return parseInt(this.element.dataset.page) + 1
  }

}
