import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    super.connect()
  }

  addFocus(event){
    let outlined_icon = `#${event.target.dataset.id}_outlined`
    let filled_icon = `#${event.target.dataset.id}_filled`
    $(outlined_icon).css('display', 'none')
    $(filled_icon).css('display', 'block')
  }

  removeFocus(event){
    let outlined_icon = `#${event.target.dataset.id}_outlined`
    let filled_icon = `#${event.target.dataset.id}_filled`
    $(outlined_icon).css('display', 'block')
    $(filled_icon).css('display', 'none')
  }

  toggleSidecardSection(){
    let sidecard_id = `#${this.element.dataset.id}`

    if($(`${sidecard_id} li:last-child`).hasClass("hidden")){
      $(`${sidecard_id} li`).removeClass("hidden")
      $(event.target).text("View Less")
    }else{
      $(`${sidecard_id} li:gt(3)`).addClass("hidden")
      $(event.target).text("View More")
    }
  }
}